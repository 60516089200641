/* global yithWcwlAddToWishlist */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProductToWishlist, removeProductFromWishlist, createWishlistAndAddProduct, moveProductToAnotherWishlist } from '../../../features/products-data/products-data-actions';
import { selectWishlists, selectWishlistsCreation } from '../../../features/wishlists/wishlists-selectors';
import useCreateWishlist from '../hooks/use-create-wishlist';
import useAddToWishlistModal from '../hooks/use-add-to-wishlist-modal';
import useMoveToAnotherWishlistModal from '../hooks/use-move-to-another-wishlist-modal';
import useProductData from '../hooks/use-product-data';
import useDropdown from '../hooks/use-dropdown';
import { useWishlistData } from '../../../hooks/use-wishlist-data';
import { reactMultipleStringReplace } from '../../../utils';
import classnames from 'classnames';

export default ( context ) => {
	const dispatch = useDispatch();

	const wishlists = useSelector( selectWishlists );
	const {
		error: creationError
	} = useSelector( selectWishlistsCreation );


	const {
		richAttributes: {
			move_label,
			modal_label,
			modal_enabled,
			tooltip_enabled,
			show_count_in_loop,
			behaviour: attribute_behaviour,
		},
		productId,
		isAdded,
		buttonId,
		is_single,
		isLoading,
		addFeedback,
		productData,
		showFeedback,
		hasDataLoaded,
		href: parentHref,
		label: baseLabel,
		style: parentStyle,
		behaviour: parentBehaviour,
		handleButtonAction,
		setFeedbackAnimation,

		isPerformingAction,
		setIsPerformingAction,
		className: parentClassName,
	} = context;

	const {
		lists,
		count,
	} = useProductData( productId );

	const {
		isCreatingWishlist,
		handleCreateWishlist,
		wishlistCreationError,
	} = useCreateWishlist();

	const {
		modalType,
		modalIcon,
		isModalOpened,
		modalWishlistId,
		modalWishlistName,
		modalWishlistNameError,
		modalWishlistSelectError,
		modalWishlistPrivacy,

		setModalType,
		setIsModalOpened,
		setModalWishlistId,
		setModalWishlistName,
		setModalWishlistPrivacy,
		setModalWishlistNameError,
		setModalWishlistSelectError,

		openAddToWishlistModal,
	} = useAddToWishlistModal( context );

	const {
		moveModalType,
		isMoveModalOpened,
		moveModalWishlistId,
		moveModalWishlistName,
		moveModalWishlistPrivacy,
		moveModalWishlistNameError,
		moveModalWishlistSelectError,

		setMoveModalType,
		setIsMoveModalOpened,
		setMoveModalWishlistId,
		setMoveModalWishlistName,
		setMoveModalWishlistPrivacy,
		setMoveModalWishlistNameError,
		setMoveModalWishlistSelectError,

		openMoveToAnotherWishlistModal,
	} = useMoveToAnotherWishlistModal();

	const tooltipEnabled = 'yes' === tooltip_enabled;

	const availableLists = Object.keys( wishlists ).filter( id => lists && ! lists.includes( Number( id ) ) )
	const {
		isDropdownOpen,
		handleCloseDropdown,
		handleToggleDropdown,
	} = useDropdown( { buttonId, availableLists } );

	const isMultiWishlist = !! yithWcwlAddToWishlist.is_multi_wishlist;
	const behaviour = 'modal' === attribute_behaviour && ! isMultiWishlist ? parentBehaviour : attribute_behaviour;
	const lastWishlist = useWishlistData( lists && lists[ 0 ] ? lists[ 0 ] : false );
	const style = [ 'custom-button', 'themed-button' ].includes( parentStyle ) && 'yes' === show_count_in_loop && ! is_single ? 'pillow' : parentStyle;
	const getWishlistURL = () => {
		if ( yithWcwlAddToWishlist?.wishlist_url_to_localize && lastWishlist?.token ) {
			return yithWcwlAddToWishlist.wishlist_url_to_localize.replace( '__wishlist_token__', lastWishlist.token )
		}

		return yithWcwlAddToWishlist?.wishlist_url || '';
	};
	const getHref = () => {
		if ( 'modal' === behaviour ) {
			return null;
		}
		return parentHref && lastWishlist?.token ? getWishlistURL() : parentHref
	}
	const href = getHref();

	const handleAddToWishlist = ( wishlistId, callback ) => handleButtonAction( async () => {
		const args = {};
		if ( isMultiWishlist && wishlistId ) {
			args.wishlist_id = wishlistId;
		}

		if ( ! args.wishlist_id && ( ! wishlists || ! Object.keys( wishlists ).length) ) {
			args.wishlist_id = 'default';
		}
		setIsPerformingAction( true );
		await dispatch( addProductToWishlist( productId, args ) );
	}, callback );

	const handleRemoveFromWishlist = ( wishlistId, callback ) => handleButtonAction(
		async () => {
			const args = {};
			setIsPerformingAction( true );
			if ( isMultiWishlist && wishlistId ) {
				args.wishlist_id = wishlistId;
			}

			await dispatch( removeProductFromWishlist( productId, args ) );
		}, callback
	);

	const handleCreateAndAddToWishlist = ( wishlistData, callback ) => handleButtonAction( async () => {
		await dispatch( createWishlistAndAddProduct( wishlistData, productId ) );
	}, callback );
	const handleMoveToAnotherWishlist = ( data = {}, callback ) => handleButtonAction( async () => {
		await dispatch( moveProductToAnotherWishlist( productId, data ) );
	}, callback );

	const onButtonClick = ( event ) => {
		dispatch( { type: 'MAYBE_INVALIDATE_DATA' } )
		if ( href ) return false;

		event?.preventDefault();
		event?.stopPropagation();

		if ( ! hasDataLoaded || isLoading ) return false;
		if ( isAdded && 'add' !== attribute_behaviour ) {
			switch ( attribute_behaviour ) {
				case 'remove':
					if ( lists?.length ) {
						handleRemoveFromWishlist( lists[ 0 ] )
					}
					break;
				case 'modal':
					isMultiWishlist && openMoveToAnotherWishlistModal();
					break;
			}
		} else {
			if ( isMultiWishlist ) {
				switch ( modal_enabled ) {
					case 'yes' :
						openAddToWishlistModal();
						return;
					case 'no' :
						if ( availableLists?.length ) {
							handleToggleDropdown();
							return
						}
				}
			}

			handleAddToWishlist();
		}
	};

	const getLabel = () => {
		if ( ! is_single && 'yes' === show_count_in_loop ) {
			return false;
		}
		if ( isAdded ) {
			if ( attribute_behaviour === 'modal' && isMultiWishlist ) return move_label;
		}
		return baseLabel;
	};

	const className = classnames(
		parentClassName,
		{
			'yith-wcwl-add-to-wishlist--with-counter': ! is_single && 'yes' === show_count_in_loop,
			'yith-wcwl-add-to-wishlist--dropdown-opened': isDropdownOpen,
		}
	)

	useEffect( () => {
		if ( (isModalOpened || isMoveModalOpened) && wishlistCreationError ) {
			addFeedback( { message: creationError, type: 'error' } );
			setIsModalOpened( false )
			setIsMoveModalOpened( false )
		}
	}, [ wishlistCreationError ] );

	useEffect( () => {
		if ( showFeedback && (isModalOpened || isMoveModalOpened) ) {
			setFeedbackAnimation( false );
			setIsModalOpened( false )
			setIsMoveModalOpened( false )
		}
	}, [ showFeedback ] );

	const movedToWishlist = useWishlistData( productData?.moved_to );

	useEffect( () => {
		if ( isPerformingAction && hasDataLoaded && productData?.moved_to ) {
			addFeedback( {
				message: reactMultipleStringReplace(
					yithWcwlAddToWishlist.i18n.feedbacks.moved,
					[
						{
							search: '{{product_name}}',
							replace: ( match, i ) => <strong key={ match + i }>"{ productData?.name }"</strong>
						},
						{
							search: '{{wishlist_name}}',
							replace: ( match, i ) => (movedToWishlist ? <strong key={ match + i }>"{ movedToWishlist?.name }"</strong> : '')
						}
					]
				),
				type: 'product_added'
			} );
		}
	}, [ productData?.moved_to ] );

	return {
		lists,
		count,
		wishlists,
		availableLists,

		href,
		style,
		behaviour,
		modalIcon,
		move_label,
		modal_label,
		className,
		tooltipEnabled,
		isDropdownOpen,
		label: getLabel(),
		wishlistURL: getWishlistURL(),

		modalType,
		moveModalType,
		isModalOpened,
		modalWishlistId,
		modalWishlistName,
		isMoveModalOpened,
		isCreatingWishlist,
		moveModalWishlistId,
		modalWishlistPrivacy,
		moveModalWishlistName,
		modalWishlistNameError,
		moveModalWishlistPrivacy,
		modalWishlistSelectError,
		moveModalWishlistNameError,
		moveModalWishlistSelectError,

		setModalType,
		setMoveModalType,
		setIsModalOpened,
		setModalWishlistId,
		setIsMoveModalOpened,
		setModalWishlistName,
		setMoveModalWishlistId,
		setModalWishlistPrivacy,
		setMoveModalWishlistName,
		setModalWishlistNameError,
		setMoveModalWishlistPrivacy,
		setModalWishlistSelectError,
		setMoveModalWishlistNameError,
		setMoveModalWishlistSelectError,

		onButtonClick,
		handleAddToWishlist,
		handleCreateWishlist,
		handleRemoveFromWishlist,
		handleMoveToAnotherWishlist,
		handleCreateAndAddToWishlist,

		handleCloseDropdown,
		handleToggleDropdown,
	}
};
