import { __ } from '@wordpress/i18n';
import classnames from 'classnames';
import reactStringReplace from 'react-string-replace';

import Modal from '../modal';
import { ModalContent, ModalActions, Input, RadioGroup, Select, Button } from '@lapilli-ui/components';
import { useButtonData, getPrivacyOptions } from '../../../../utils';
import { useMoveToAnotherWishlistModalData } from './hooks/use-move-to-another-wishlist.modal-data'

const MoveToAnotherWishlistModal = ( { className } ) => {
	const {
		modalIcon,
		moveModalWishlistId,
		moveModalWishlistNameError,
		moveModalWishlistName,
		moveModalType,
		moveModalWishlistPrivacy,
		isMoveModalOpened,
		moveModalWishlistSelectError,
	} = useButtonData();

	const {
		className: modalClasses,
		handleClose,
		chooseOptions,
		originWishlistName,
		handlePrivacyChange,
		handleRemoveFromWishlist,
		handleWishlistSelectChange,
		handleMoveToAnotherWishlist,
		handleWishlistNameInputChange,
	} = useMoveToAnotherWishlistModalData();

	const classes = {
		modal: classnames( className, modalClasses ),
		title: 'yith-wcwl-move-to-another-wishlist-modal__title'
	};
	const getSelectValue = () => {
		if ( 'create' === moveModalType )
			return 'create'

		return moveModalWishlistId > 0 ? moveModalWishlistId : undefined;
	};

	return <Modal
		open={ isMoveModalOpened }
		onClose={ handleClose }
		classes={ classes }
		icon={ 'default' === modalIcon ? 'heart-arrows-light' : modalIcon }
		title={ yithWcwlAddToWishlist.i18n.modals.move_to_wishlist.title }
	>
		<ModalContent className={ 'yith-wcwl-modal__content yith-wcwl-move-to-another-wishlist-modal__content' }>
			<div className={ 'yith-wcwl-move-to-another-wishlist-modal__content__desc' }>
				{ reactStringReplace( yithWcwlAddToWishlist.i18n.modals.move_to_wishlist.description, '{{wishlist_name}}', ( match, i ) => <strong key={ i }>"{ originWishlistName }"</strong> ) }
				<br/>
				{ yithWcwlAddToWishlist.i18n.modals.move_to_wishlist.move_it_to }
			</div>
			<Select
				options={ chooseOptions }
				placeholder={ yithWcwlAddToWishlist.i18n.modals.move_to_wishlist.choose_wishlist_placeholder }
				error={ moveModalWishlistSelectError }
				onChange={ handleWishlistSelectChange }
				value={ getSelectValue() }
				defaultValue={ chooseOptions[ 0 ]?.value }
			/>
			{
				'create' === moveModalType &&
				<>
					<Input
						placeholder={ yithWcwlAddToWishlist.i18n.modals.add_to_wishlist.wishlist_name_input_placeholder }
						value={ moveModalWishlistName }
						onChange={ handleWishlistNameInputChange }
						error={ moveModalWishlistNameError }
						fullWidth={ true }
					/>
					<RadioGroup
						options={ getPrivacyOptions( true ) }
						value={ moveModalWishlistPrivacy }
						onChange={ handlePrivacyChange }
					/>
				</>
			}
		</ModalContent>
		<ModalActions className={ 'yith-wcwl-modal__actions yith-wcwl-move-to-another-wishlist-modal__actions' }>
			<Button className={ classnames( yithWcwlAddToWishlist.buttonClasses, 'yith-wcwl-modal__primary-button' ) } onClick={ handleMoveToAnotherWishlist } size={ 'lg' }>
				{ yithWcwlAddToWishlist.i18n.modals.move_to_wishlist.move_button }
			</Button>
			<span className={ 'yith-wcwl-move-to-another-wishlist-modal__remove-wrapper' }>
					{ __( 'or', 'yith-woocommerce-wishlist' ) }
				<a role={ 'button' } className={ 'yith-wcwl-move-to-another-wishlist-modal__remove' } onClick={ handleRemoveFromWishlist }>
						{ yithWcwlAddToWishlist.i18n.modals.move_to_wishlist.remove_button }
					</a>
				</span>
		</ModalActions>
	</Modal>
}

export default MoveToAnotherWishlistModal;